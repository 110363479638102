import React, { useState, useEffect } from 'react'
import {
  Text,
  Heading,
  Container,
  Stack,
  Divider,
  // ListItem,
  // OrderedList,
  // UnorderedList,
  Button,
} from '@chakra-ui/react'
import { graphql } from 'gatsby'
import Foswig from 'foswig'
import BaseLayout from '../../layout/base'
import {
  Heading1,
  Heading2,
  Heading3,
  // Heading4,
  // Heading5,
  // Heading6,
  Paragraph,
} from '../../components/ContentWithStyles/TransformToChakra'
import SEO from '../../components/SEO/PageSEO'

const Page = ({ location, data }) => {
  const chain = new Foswig(
    3,
    data.wordsJson.spanish.nouns
      .concat(data.wordsJson.spanish.adjectives)
      .concat(data.wordsJson.spanish.verbs)
  )

  const constraints = {
    minLength: 4,
    maxLength: 10,
    allowDuplicates: false,
  }

  const [word, setWord] = useState(chain.generate(constraints))

  useEffect(() => {
    setWord(chain.generate(constraints))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BaseLayout>
      <SEO
        title="Generador de palabras inventadas aleatorias"
        titleSeo="Generador de palabras inventadas aleatorias"
        description="Herramienta para generar palabras inventadas al azar en español."
        image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
        // lang="es"
        datePublished="2021-05-11T12:29:33-03:00"
        dateModified="2021-05-11T12:29:33-03:00"
        breadcrumbs={[{ name: 'Palabras', url: '/palabras/' }]}
        permalink={location.pathname}
        // breadcrumbList={[]}
        // noindex={false}
      />
      <Container maxW="5xl">
        <Stack
          textAlign="center"
          align="center"
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight="110%"
            as="span"
          >
            <Text as="span" color="orange.400">
              {word}
            </Text>
          </Heading>
          <Heading as="h2" color="gray.500" size="md" fontWeight={500}>
            Generador de palabras inventadas y aleatorias
          </Heading>

          <Stack spacing={2} direction="row">
            <Button
              rounded="full"
              px={6}
              colorScheme="orange"
              bg="orange.400"
              _hover={{ bg: 'orange.500' }}
              onClick={() => setWord(chain.generate(constraints))}
            >
              Generar
            </Button>
          </Stack>
        </Stack>
      </Container>
      <Divider />
      <Container maxW="5xl" my={10}>
        <Heading1>
          Generador de palabras inventadas: son aleatorias y no existen
        </Heading1>
        <Paragraph>
          Esta herramienta online cumple la funci&oacute;n de un generador de
          palabras inventadas en espa&ntilde;ol, es decir, se generan{' '}
          <strong>
            palabras al azar que son muy similares a las palabras ya existentes
          </strong>{' '}
          en el idioma espa&ntilde;ol,{' '}
          <strong>pero que en realidad no existen</strong>, por lo que son
          palabras inventadas con alto grado de similitud a las que puedas
          conocer si hablas espa&ntilde;ol.
        </Paragraph>
        <Paragraph>
          &iquest;C&oacute;mo? &iquest;Palabras inventadas? Para dejarlo en
          claro,{' '}
          <strong>
            no son palabras existentes seleccionadas de forma aleatoria, sino
            que son palabras totalmente inventadas
          </strong>{' '}
          y que no cuentan con definici&oacute;n alguna en el idioma
          espa&ntilde;ol. No existen, pero su estructura es muy similar a las
          que s&iacute; existen, esto es a fines de simular ser palabras del
          idioma espa&ntilde;ol, pero mantener la rareza de ser inventadas y
          desconocidas.
        </Paragraph>
        <Paragraph>
          Un buen generador de palabras ficticias en espa&ntilde;ol no se limita
          a dar un conjunto de caracteres al azar, sino que crear nuevas
          palabras muy familiares a las que nos podemos encontrar en el idioma
          espa&ntilde;ol.
        </Paragraph>
        <Heading2>
          &iquest;Cu&aacute;l es la utilidad de generar palabras inventadas?
        </Heading2>
        <Paragraph>
          Poder obtener muchas palabras inventadas que se asemejen a
          t&eacute;rminos presentes en el idioma espa&ntilde;ol puede tener
          muchos usos pr&aacute;cticos, dado que el proceso de crear nuevas
          palabras muchas veces resulta dif&iacute;cil para nuestros, los
          humanos, porque tendemos a recurrir a lo que ya conocemos y es
          dif&iacute;cil imaginar estructuras alternativas sin caer en repetir
          palabras que quiz&aacute; sean raras o desconocidas, pero no
          inventadas realmente, dado que s&iacute; existen.
        </Paragraph>
        <Paragraph>
          Para esto es que el generador de palabras inventadas en espa&ntilde;ol
          resulta muy &uacute;til, dado que con el simple toque de un
          bot&oacute;n obtendremos una nueva palabra inventada que no existe,
          pero es f&aacute;cil de leer y se parece a otras que s&iacute;
          existen, sin esfuerzo ni espera alguna, podremos obtener tantas
          palabras como queramos.
        </Paragraph>
        <Paragraph>
          En principio, las posibles aplicaciones de una herramienta como esta
          son muchas, y depender&aacute; del contexto el c&oacute;mo
          ser&aacute;n utilizadas las palabras falsas creadas con este
          generador, por lo que no tendr&iacute;a sentido el intentar hacer una
          lista exhaustiva de posibles usos, pero a modo de ejemplo, veamos
          algunos escenarios en los cuales resulta muy &uacute;til tener a
          nuestra disposici&oacute;n una utilidad para generar palabras
          ficticias.
        </Paragraph>
        <Heading3>Para juegos de mesa y actividades recreativas</Heading3>
        <Paragraph>
          Muchos juegos y actividades grapales requieren el uso de palabras
          inventadas o falsas, ante lo cual un generador de palabras que no
          existen resulta muy &uacute;til que ya nos permite ahorrarnos mucho
          tiempo y centrarnos en la parte divertida de la actividad.
        </Paragraph>
        <Paragraph>
          Ya sean juegos de palabras, de fantas&iacute;a, que requieran de un
          guion, desaf&iacute;os ling&uuml;&iacute;sticos, o sopas de letras
          extra&ntilde;as, siempre nos puede ser de utilidad tener una
          herramienta para generar palabras inventadas a montones en un
          instante.
        </Paragraph>
        <Heading3>
          Para escribir historias y crear mundos fant&aacute;sticos
        </Heading3>
        <Paragraph>
          Otro posible uso de las palabras inventadas es el crear mundos de
          fantas&iacute;a y escribir relatos donde necesitemos de palabras
          especiales y propias, pero que sean f&aacute;ciles de identificar,
          pronunciar y recordar para que no entorpezcan la lectura en un intento
          por agregar originalidad.
        </Paragraph>
        <Paragraph>
          Las palabras generadas por esta herramienta online no existen y no
          tienen definici&oacute;n alguna, pero simular ser palabras reales y
          son muy similares en estructura a las palabras que podr&iacute;as
          encontrar en un diccionario del idioma espa&ntilde;ol, con lo cual
          encajan perfectamente en un libro o narraci&oacute;n en espa&ntilde;ol
          que requiera un toque de creatividad mediante palabras curiosas y
          nuevas.
        </Paragraph>
        <Heading3>Para crear idiomas ficticios</Heading3>
        <Paragraph>
          Este punto est&aacute; relacionado al anterior, dado que m&aacute;s
          all&aacute; de introducir elementos a nuestro mundo de
          fantas&iacute;a, podemos en su lugar utilizar este generador de
          palabras inventadas online para crear todo un lenguaje con
          t&eacute;rminos muy similares al idioma espa&ntilde;ol pero
          inexistentes, con lo cu&aacute;l ser&aacute; un idioma original,
          creativo y repleto de palabras inventadas que despertar&aacute;n el
          inter&eacute;s del lector, &iexcl;e incluso el idioma inventado se
          podr&aacute; aprender y hablar!
        </Paragraph>
        <Paragraph>
          Evidentemente la reglas que rijan dicho lenguaje ser&aacute;n
          responsabilidad del escritor, si es que desea incluirlas, pero las
          palabras se pueden obtener directamente con este generador online en
          cuesti&oacute;n de segundos, permitiendo as&iacute; un uso mucho
          m&aacute;s eficiente del tiempo y la creatividad.
        </Paragraph>
        <Heading2>No son colecciones de caracteres al azar</Heading2>
        <Paragraph>
          Algo a destacar es que no se trata de una mera colecci&oacute;n de
          letras en un orden aleatorio, sino que el generador crea palabras que
          no existen, pero se asemejan mucho a las que s&iacute; existen dentro
          del idioma espa&ntilde;ol.
        </Paragraph>
        <Paragraph>
          Una mera colecci&oacute;n de letras en orden aleatorio dar&iacute;a
          lugar a palabras demasiado extra&ntilde;as, dif&iacute;ciles de
          pronunciar, que la gente tendr&iacute;a que esforzarse mucho por poder
          utilizar y recordar. M&aacute;s a&uacute;n cuando pensando en todas
          las posibles combinaciones que ni siquiera tienen pronunciaci&oacute;n
          alguna en el idioma espa&ntilde;ol, &iquest;De qu&eacute;
          servir&iacute;a crear palabras como &ldquo;JGHNGFN&rdquo; o
          &ldquo;QMRBCPA&rdquo;? Ni siquiera calificar&iacute;an como palabras,
          son caracteres agrupados sin sentido alguno.
        </Paragraph>
        <Paragraph>
          Es por eso que el algoritmo con el que cuenta este generador online de
          palabras inventadas al azar te dar&aacute; resultados atractivos si ya
          conoces el idioma espa&ntilde;ol, porque te presentar&aacute; palabras
          f&aacute;ciles de pronunciar, con estructuras familiares, y siempre
          originales, sin importar cu&aacute;ntas palabras inventadas requieras.
        </Paragraph>
        <Heading3>Una herramienta simple con geniales resultados</Heading3>
        <Paragraph>
          La herramienta en s&iacute; misma se limita a generar palabras
          inventadas de forma aleatoria, pero manteniendo las
          caracter&iacute;sticas comunes del espa&ntilde;ol, por lo que su
          funcionamiento y usos son tan simples como concisos, lo cual no le
          resta ni un solo punto a su capacidad creativa y las infinitas
          aplicaciones posibles para las palabras inventadas que han sido
          generadas.
        </Paragraph>
        <Paragraph>
          Este generador de palabras que no existen no pretende ser un aparato
          complejo ni abrumar al usuario con muchas opciones y configuraciones,
          sino que, por el contrario, su simplicidad es una de sus puntos
          fuertes, dado que la creaci&oacute;n de palabras inventadas se hace de
          forma r&aacute;pida y c&oacute;moda, quitando toda p&eacute;rdida de
          tiempo y molestia del camino.
        </Paragraph>
        <Paragraph>
          A su vez, en cuanto a los resultados, &eacute;stos tambi&eacute;n son
          muy simples pero &uacute;tiles, dado que como ya se ha explicado en
          p&aacute;rrafos anteriores, las palabras inventadas mantienen cierta
          familiaridad con el idioma espa&ntilde;ol y esto permite obtener
          palabras muy simples, pero a su vez atractivas y f&aacute;ciles de
          utilizar.
        </Paragraph>
        <Paragraph>
          El objetivo de este generador de palabras ficticias es facilitar la
          creaci&oacute;n de nuevas palabras al usuario, present&aacute;ndole
          una interfaz de lo m&aacute;s simple, sin configuraciones ni pasos
          intermedios, que con el toque de un bot&oacute;n permite obtener una
          nueva palabra al instante.
        </Paragraph>
      </Container>
    </BaseLayout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    wordsJson {
      spanish {
        nouns
        adjectives
        verbs
      }
    }
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
